/* Root Level definitions */

/* Estilos para desktop */

@media (min-width: 1923px) {
	
	html {
	  font-size: 110%;
	}
		
}

@media (min-width: 1024px) and (max-width: 1920px) {
	
	html {
	  font-size: 100%;
	}
		
}

/* Only 1024 */
@media (width: 1024px) {
	
	html {
	  font-size: 90%;
	}

}

/* Estilos para tablet */
@media (min-width: 600px) and (max-width: 1023px) {
	html {
	  font-size: 100%;
	}
	
	
	.overlayPanel {
		width:30%;
	}	

}

/* Estilos para mobile */
@media (max-width: 600px) {
	html {
	  font-size: 90%;
	}

	
	.overlayPanel {
		width:100%;
	}	
	
}

/* Main page wrapper */
.wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* Wrapper Header */
.wrapper__header {
	width: 100%;
	height: 70px;
	background-color: #FFF;
	display: flex;
	position: sticky;
    z-index: 999;
    top: 0;	
}

/* Wrapper Header Container */
.wrapper__header_container {
	margin-left: auto;
	margin-right: auto;
	max-width: 1185px;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	
	/* For Debug */
	border-width: 0px;
	border-color: #990000;
	border-style: solid;
}

/* Wrapper Content */
.wrapper__content {
	width: 100%;
	min-height: 785px;
	background-color: #F9F9F9;
}

/* Wrapper Content Container */
.wrapper__content_container {
	margin-left: auto;
	margin-right: auto;
	max-width: 1440px;
	position:relative;
	
	/* For Debug */
	border-width: 0px;
	border-color: #990000;
	border-style: solid;
}

.wrapper__content_container.bgPurple {
	background-color: rgb(46, 2, 73);	
}

.wrapper__content_container.home {
	max-width: unset !important;
}

/* Wrapper Footer */
.wrapper__footer {
	width: 100%;
	height: 50px;
	
	background:linear-gradient(254.34deg, #171717 -10.49%, #000000 105.57%);
	color:rgba(255, 255, 255, 0.4);
	display:flex;
	align-items:center;
	justify-content:center;	
}

/* Wrapper Footer Container */
.wrapper__footer_container {
	margin-left: auto;
	margin-right: auto;
	max-width: 1440px;
	
	display:flex;
	align-items:center;
	justify-content:center;	
	
	/* For Debug */
	border-width: 0px;
	border-color: #990000;
	border-style: solid;
}

.w100 {
	width:100% !important;
}

.w90 {
	width:90% !important;
}

/* Handy selectors */
.dFlex {
	display:flex;	
}

.flex-1 {
	flex:1;
}

.flex-1_5 {
	flex:1.5;
}

.flex-2 {
	flex:2;
}

.flex-3 {
	flex:3;
}

.flex-4 {
	flex:4;
}

.flex-5 {
	flex:5;
}

.flex-6 {
	flex:6;
}

.aItemsC {
	align-items:center !important;
}

.jContentS {
	justify-content:start !important;
}

.jContentE {
	justify-content:end !important;
}

.tAlignL {
	text-align:left !important;
}

.tAlignC {
	text-align:center !important;
}

.tAlignR {
	text-align:right !important;
}

.pL10 {
	padding-left:10px !important;
}

.pT10 {
	padding-top:9px !important;
}

.pL40 {
	padding-left:40px !important;
}

.pR10 {
	padding-right:10px !important;
}

.mL5 {
	margin-left:5px !important;
}

.mL10 {
	margin-left:10px !important;
}

.mL20 {
	margin-left:20px !important;
}

.mR5 {
	margin-right:5px !important;
}

.mR10 {
	margin-right:10px !important;
}

.mR20 {
	margin-right:20px !important;
}


/* Custom Selectors to override */
.p-overlaypanel-content {
  padding:0 !important;
}


.headerButton {
    float: left;
    height: 42px;
    color: #222222 !important;
}

.headerButton span {
    font-weight: 600 !important;
}

.tColorWhite {
	color:#FFFFFF !important;
}

.tColorPink {
	color:#fe346e !important;
}

/* Hide blue square when tap on mobiel device*/
input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}

/* Avoid blue selection on desired element*/
.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.noSelect:focus {
    outline: none !important;
}



/* ANIMATIONS */

.imagem-zoom {
    transition: transform 0.3s ease-in-out;
}

.imagem-zoom:hover {
    /*transform: scale(1.03);*/
	cursor:pointer;
}

/**
 * Remove focus styles for non-keyboard focus. REACT HACK
 */
:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

.p-autocomplete-panel {
max-width:350px !important;
}

.p-autocomplete-item {

	font-size:0.8rem !important;
	white-space: unset;
	
}

/*
.p-overlaypanel {
	z-index:9 !important;
}


div.modalWrapper > .p-overlaypanel {
	z-index:99999 !important;
}*/


/* Overwrite panel default border */
.cardPanel .p-panel-content {
    padding: 0.25rem !important;
    border: 0px !important;
}



.floatingRouteButton {
-webkit-box-shadow: -11px 15px 29px -3px rgba(112,112,112,0.69);
-moz-box-shadow: -11px 15px 29px -3px rgba(112,112,112,0.69);
box-shadow: -11px 15px 29px -3px rgba(112,112,112,0.69);
}

.routePanel {
    transition: all 0.5s ease-in-out;
	z-index:3105;
	width:0%;
	height:100vh;
	position:fixed !important;
	right:0;
	-webkit-box-shadow: -19px 0px 27px -12px rgba(92,92,92,1);
	-moz-box-shadow: -19px 0px 27px -12px rgba(92,92,92,1);
	box-shadow: -19px 0px 27px -12px rgba(92,92,92,1);
}

.routePanel.animate {    
	width:35vw;
}


.routeManage {
    transition: all 0.5s ease-in-out;
	z-index:3105;
	width:0%;
	height:calc(100vh - 70px);
	position:fixed !important;
	left:0;
	-webkit-box-shadow: 10px 0px 19px 1px rgba(87,87,87,1);
	-moz-box-shadow: 10px 0px 19px 1px rgba(87,87,87,1);
	box-shadow: 10px 0px 19px 1px rgba(87,87,87,1);
	position:absolute;
	top:70px;	
}

.routeManage.animate {    
	width:40vw;
}

/* Force fontSize card label status button*/
.cardLabelStatus span.p-inline-message-text {
	font-size:12px !important;
	    
}

.cardLabelStatus {
	padding: 0.5rem 0.5rem;	    
}

.cardLabelStatusPayment span.p-inline-message-text {
	font-size:0.85rem !important;
	    
}

.cardLabelStatusPayment {
	padding: 0.5rem 0.5rem;	    
}

.p-contextmenu {
	z-index:3200 !important;
}


.loggedInWrapper {
	
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
    border: 1px dashed silver;
    border-radius: 10px;
    margin: 5px;
    padding: 5px;
    font-size: 14px;

}

.adminButtonLabel{
	
color: rgba(0, 0, 0) !important;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 143%; /* 20.02px */
letter-spacing: 0.17px;
text-align:left;
width:100%;
	
}

.adminButtonLabel.selected {
	color:#fe346e !important;
}

.adminButtonLabel.first {
	margin-top:20px !important;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: #2E0249 !important;
    color: #fff !important;
}

.advSearchPanel {
    transition: all 0.5s ease-in-out;	
	opacity: 0 !important;
	max-height:0;
}

.advSearchPanel.animate {    
	opacity: 1 !important;
	max-height:500px;
}

#filterOption > span {
	font-size:14px !important;
}




.product-badge.status-naoativado {
    background: #ffcdd2;
    color: #c63737;
}

.product-badge.status-info {
    background: whitesmoke;
    color: gray;
}


.product-badge.status-ativado {
    /*background: #c8e6c9;
    color: #256029;*/
	
    background: green;
    color: #fff;	
	
}

.product-badge.status-bloqueado {
   /* background: #ffcdd2;
    color: #c63737;*/
	
    background:red;
    color: #fff;	
}

.product-badge.status-encerrado {
    background: #000;
    color: #FFF;
}


.product-badge.status-review {
    background: orange;
    color: #FFF;
}

.product-badge {
    border-radius: 5px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    letter-spacing: .3px;
	/*width:80% !important;*/
	max-width:200px;
	text-align:center;
	text-wrap: nowrap;
}

.p-datatable-tbody tr {
	vertical-align:baseline;
}

.filterButton {
	overflow:unset !important;
}

.filterButton .p-badge-secondary {

position: absolute;
right: -6px;
top: -7px;
z-index: 999999999;
	
}

.p-badge-menu {
	margin-top:-10px !important;
}

.p-card .p-card-body {
	padding:0 !important;
}

.p-card .p-card-content {
    padding:0 !important;
}

.p-card .p-card-title {
   margin-bottom: 0 !important;
}

.verticalTabLabel {
	font-weight:700 !important;
	font-size:1rem !important;
	text-transform: unset !important;
	
	flex-wrap: nowrap !important;
	align-items: end !important;
}


.p-scrollpanel .p-scrollpanel-bar {
    background: purple;
    border: 0 none;
}

.fBlack {
	color:#000 !important;
}


.panel_data_container {
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: center; 
	align-items: flex-start;
	padding:10px;
	border:1px solid #e6e6e6;
	border-radius:10px;
}

.panel_data_title {
	font-weight: bold;
	text-align:left;
	margin:5px
}
.panel_data_value {
	font-weight:normal ;
	text-align:left;
	margin:5px
}


/* Flex selectors */
.flex-row {
	display: flex;
	flex-direction: row; /* Itens alinhados horizontalmente */
  }
  
  .flex-column {
	display: flex;
	flex-direction: column; /* Itens alinhados verticalmente */
  }

  .justify-start {
	justify-content: flex-start; /* Alinha itens ao início do container */
  }
  
  .justify-end {
	justify-content: flex-end; /* Alinha itens ao fim do container */
  }
  
  .justify-center {
	justify-content: center; /* Centraliza itens */
  }
  
  .justify-between {
	justify-content: space-between; /* Espaço entre os itens */
  }
  
  .justify-around {
	justify-content: space-around; /* Espaço distribuído uniformemente em torno dos itens */
  }

  .align-items-start {
	align-items: flex-start; /* Alinha itens ao início transversal */
  }
  
  .align-items-end {
	align-items: flex-end; /* Alinha itens ao fim transversal */
  }
  
  .align-items-center {
	align-items: center; /* Alinha itens ao centro transversal */
  }
  
  .align-items-stretch {
	align-items: stretch; /* Estica itens para preencher o container */
  }
  
  .align-items-baseline {
	align-items: baseline; /* Alinha itens à linha de base do texto */
  }
  
  .flex-wrap {
	flex-wrap: wrap; /* Itens serão envoltos em uma nova linha/coluna */
  }
  
  .flex-nowrap {
	flex-wrap: nowrap; /* Itens não serão envoltos */
  }

.green {
	color:green !important;
}  

.red {
	color:red !important;
}

.orange {
	color:orange !important;
}

