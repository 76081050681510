/* Root Level definitions */

/* Estilos para desktop */

@media (min-width: 1923px) {
	
	html {
	  font-size: 110%;
	}
		
}

@media (min-width: 1024px) and (max-width: 1920px) {
	
	html {
	  font-size: 100%;
	}
	
	/* Login Section Form Wrapper */
	.login__wrapper_form_wrapper {
	  height: 30vw;
	}

	/* Login Section Concept Image Wrapper */
	.login__wrapper_conceptImage_wrapper {
	  height: 30vw;
	}
		
}

@media (min-width: 1024px) and (max-width: 1520px) {
	
	html {
	  font-size: 100%;
	}

		
}

@media (min-width: 1025px) and (max-width: 1130px) {
	
	html {
	  font-size: 90%;
	}

		
}

/* Only 1024 */
@media (width: 1024px) {
	
	html {
	  font-size: 90%;
	}
	
	.conceptImage {
		top:15% !important;		
		max-width:520px !important;
		left:-60px !important;
	}	

	.textBlockMargin {
		padding-left: 10px;
		padding-right: 10px;
	}
			
		
}

/* Estilos para tablet */
@media (min-width: 600px) and (max-width: 1023px) {
	html {
	  font-size: 100%;
	}
	
	#contatoSection {
		padding-bottom: 40px !important;
	}
	
	.textBlockMargin {
		margin-top:50px;
		height:unset !important;
	}
	
	.wrapper__content_container.mobile {
		background:unset !important;
	}	
			

	.contatoSection__wrapper_content_wrapper.mobile div.tAlignL {
		text-align:center !important;
	}
	
	.login__form_wrapper {
		margin-top: 2.5rem !important;
	}					
	
	.login__wrapper_form_wrapper_cornerImage, .login__wrapper_conceptImage_wrapper_cornerImage {
		display:none;
	}
	
	.login__form_wrapper div {
		margin-right:unset !important;
	}
	
	.login__wrapper_conceptImage_wrapper {
		min-height: unset !important;
	}	

}

/* Estilos para mobile */
@media (max-width: 600px) {
	html {
	  font-size: 90%;
	}
	
	.textBlockMargin {
		padding-left: 30px;
		padding-right: 30px;
		height:unset !important;
	}


	.wrapper__content_container.mobile {
		background:unset !important;
	}
		
	.login__wrapper.mobile img {
		max-width: 310px !important;
	}

	.contatoSection__wrapper_content_wrapper.mobile div.tAlignL {
		text-align:center !important;
	}
	
	.login__form_wrapper {
		margin-top: 2.5rem !important;
		padding-left:1rem !important;
		padding-right:1rem !important;
	}					
	
	.login__wrapper_form_wrapper_cornerImage, .login__wrapper_conceptImage_wrapper_cornerImage {
		display:none;
	}
	
	.login__form_wrapper div {
		margin-right:unset !important;
	}
	
	.login__wrapper_conceptImage_wrapper {
		min-height: unset !important;
	}		
	
	
}

/* Login Section Wrapper */
.login__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 55px;
}

/* Login Section Wrapper */
.login__wrapper.mobile {
  flex-direction: column !important;
    margin-top: 0px !important;
}


/* Login Section Wrapper */
.login__wrapper.mobile img {
	margin-right:unset !important;
	/*max-width:unset !important;*/
	max-width: 500px;
	margin-bottom:30px
}

/* Login Section Form Wrapper */
.login__wrapper_form_wrapper {
  display: flex;
  flex: 1;
  height: 40vw;
  min-height:36.25rem;
  background-color: #2E0249;
  position:relative;
}

/* Login Section Form Wrapper */
.login__wrapper_form_wrapper.mobile {
  background-color: unset !important;
}

/* Login Section Concept Image Wrapper */
.login__wrapper_conceptImage_wrapper {
  flex: 1;
  height: 40vw;
  background-color: #2E0249;
  min-height:36.25rem;
 /* border-radius: 135px 0px 0px 0px;*/
  position: relative;
  display:flex;
  align-items: center;
}

/* Login Section Concept Image Wrapper */
.login__wrapper_conceptImage_wrapper.mobile {
    background-color: unset !important;
}

/* Login Section Concept Image  */
.login__wrapper_conceptImage_image {
  width: 100%;
  min-width: 34vw;
  /*max-width: 535px;*/
  max-width: 585px;
  height: auto;
  position: absolute;
  /*top: 15%;*/
  left: -70px;
  /*animation: 60s linear 0s normal none infinite running zoomin;
  -webkit-animation: 60s linear 0s normal none infinite running zoomin;
  -o-animation: 60s linear 0s normal none infinite running zoomin;
  -moz--o-animation: 60s linear 0s normal none infinite running zoomin;*/
}

/* Login Section Concept Image  */
.login__wrapper_conceptImage_image.mobile {
  position: unset;
/*  animation: 60s linear 0s normal none infinite running zoomin;
  -webkit-animation: 60s linear 0s normal none infinite running zoomin;
  -o-animation: 60s linear 0s normal none infinite running zoomin;
  -moz--o-animation: 60s linear 0s normal none infinite running zoomin;*/
}

@keyframes zoomin {
    0% { transform: scale(1, 1) }
    50% { transform: scale(1.2, 1.2) }
	100% { transform: scale(1, 1) }
}


/* Login Section Form Wrapper  */
.login__form_wrapper {
  flex: 1;
 /* border-radius: 0px 0px 135px 0px;*/
  background-color: #F9F9F9;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: -1px;
  flex-direction:row;

}

/* Memovo Section Wrapper*/
.memovoSection__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2E0249;
  padding-bottom: 30px;
  padding-top: 30px;
}


/* Memovo Section Wrapper Content Wrapper*/
.memovoSection__wrapper_content_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #2E0249;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* Memovo Section Wrapper Content Wrapper*/
.memovoSection__wrapper_content_wrapper.mobile {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  background-color: #2E0249;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* Memovo Section Content Image */
.memovoSection__wrapper_content_image {
  width: 100%;
  max-width: 745px;
  height: auto;
}

/* Memovo Section Content Text */
.memovoSection__wrapper_content_text {
  flex: 1;
  color: #FFFFFF;
  /*height: 308px;*/
  max-width: 500px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-align: left;
}

/* Memovo Section Content Header */
.memovoSection__wrapper_content_header {
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 120%;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  margin-bottom: 15px;
}


/* Contato Section Wrapper*/
.contatoSection__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #570A57;
  padding-bottom: 30px;
  padding-top: 20px;
}



/* Contato Section Wrapper Content Wrapper*/
.contatoSection__wrapper_content_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}


/* Contato Section Wrapper Content Wrapper*/
.contatoSection__wrapper_content_wrapper.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}


/* Contato Section Content Image */
.contatoSection__wrapper_content_image {
  width: 90%;
  max-width: 628px;
  height: auto;
}


/* Contato Section Content Text */
.contatoSection__wrapper_content_text {
  flex: 1;
  color: #FFFFFF;
  /*height: 335px;*/
  max-width: 400px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-align: left;
}

/* Contato Section Content Header */
.contatoSection__wrapper_content_header {
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 120%;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  margin-bottom: 15px;
  text-align: left;
}

.passwordInput, .emailInput {
	width:100%;
	height:56px;
}

.passwordInput input {
	width:100%;
	height:56px;
}

.p-input-icon-right>i:last-of-type, .p-input-icon-right>svg:last-of-type, .p-input-icon-right>.p-input-suffix {
    color: #FE346E;
	font-weight:800;
}

.forgotPasswordButton {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
	text-align: center;
	letter-spacing: 0.1em;
	color:#FE346E;
	cursor:pointer;
}

.formTools {
	display:flex;
	flex-direction:row;
	gap:1.5rem;
}

.formActions {
	display:flex;
	flex-direction:column;
	margin-top:2rem;
}

.formTitle {
	margin: 0;
	margin-bottom: 1.25rem;
	font-size: 2.25rem;
	color: #373737;
	font-weight: 600;
	line-height: 120%;
	letter-spacing: 0.1em;
}

.formSubTitle {
	margin: 0;
	margin-bottom: 2.5rem;
	font-size: 1.25rem;
	color: #6B6B6B;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 0.1em;
}

.formWrapper {
	width: 100%;
	margin-right: 15%;
	max-width: 27.5rem;
	height: auto;
}

.contatoSection__wrapper_content_formTop_image {
	width: 8.625rem;
	height: auto;
	align-self: center;
	margin-bottom: 1.875rem;	
}

.login__wrapper_form_wrapper_cornerImage {
	width: 9.375rem;
	height: auto;
	position: absolute;
	right: -1px;
	bottom: -1px;	
}

.login__wrapper_conceptImage_wrapper_cornerImage {
	width: 150px;
	height: auto;
	position: absolute;
	left: -1px;
	top: -1px;	
} 

.contatoOptionText {
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #FFFFFF;	
}