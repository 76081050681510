/* Root Level definitions */

/* Estilos para desktop */

@media (min-width: 1923px) {
	
	html {
	  font-size: 110%;
	}
		
}

@media (min-width: 1024px) and (max-width: 1920px) {
	
	html {
	  font-size: 100%;
	}
			
}

@media (min-width: 1024px) and (max-width: 1520px) {
	
	html {
	  font-size: 100%;
	}

		
}

@media (min-width: 1025px) and (max-width: 1130px) {
	
	html {
	  font-size: 90%;
	}

		
}

/* Only 1024 */
@media (width: 1024px) {
	
	html {
	  font-size: 90%;
	}
			
}

/* Estilos para tablet */
@media (min-width: 600px) and (max-width: 1023px) {
	html {
	  font-size: 100%;
	}
	
}

/* Estilos para mobile */
@media (max-width: 600px) {
	html {
	  font-size: 90%;
	}
	
	
}

.registerModalIntro {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 120%;
	text-align: left;
	letter-spacing: 0.1em;
	color:#6B6B6B;
	margin-bottom:10px;
	margin-top: 15px;
}

.p-password-input {
	width:100%;
}

.p-panel .p-panel-header {
    /*background: #570A57 !important;*/
	color:#FFF !important;
	background: rgb(87,10,87);
	background: linear-gradient(180deg, rgba(87,10,87,1) 0%, rgba(117,60,139,1) 100%) !important;
}

.p-dialog .p-dialog-footer {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem !important;
}

.terms h1 {
	font-size: 2rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

.terms h2 {
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 0.75rem;
}

.terms p {
    margin-bottom: 1rem;
}

.modalFooterButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;	
}

.modalFooterButtonsEnd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;	
}

.modalFooterButtonsLeft {
	flex: 2;
	text-align: start;	
}

.modalFooterButtonsRight {
	flex: 1.5;
}

.requiredField {
	color:red;
}